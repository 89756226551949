<template>
  <div
    :class="{
      'with-background': !transparent,
      [size]: true,
    }"
    class="c-user-stack"
  >
    <div class="flex items-center">
      <BittsAvatar
        v-for="(user, i) in limitedUsers"
        :key="`user_${i}`"
        class="c-user-stack__user"
        :class="{ translated: i > 0 }"
        :style="{ 'z-index': i }"
        :user="user || {}"
        :show-initials="!!(user?.first_name && user?.last_name)"
        :size="size"
        :include-hover-text="includeHoverText"
      >
        <template #additionalInfo>
          <p>{{ user?.email }}</p>
          <p
            v-if="user?.last_update_date"
            class="text-neutral-text-placeholder"
          >
            {{
              `Last updated ${DateTime.fromISO(user?.last_update_date).toRelative()}`
            }}
          </p>
        </template>
      </BittsAvatar>
      <div v-if="overflow" class="overflow-text"> +{{ overflow }} </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DateTime } from 'luxon';
import { computed } from 'vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import {
  Size as BittsAvatarSize,
  TSize as BittsAvatarSizeType,
} from '../BittsAvatar/types';

import { UserType } from './types';

export type Props = {
  transparent?: boolean;
  size?: BittsAvatarSizeType;
  users?: UserType[];
  includeHoverText?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  transparent: false,
  size: BittsAvatarSize.Medium,
  users: () => [],
  includeHoverText: false,
});

const overflow = Math.max(props.users.length - 5, 0);
const limitedUsers = computed(() => props.users.slice(0, 5));
</script>
<style lang="pcss" scoped>
.c-user-stack {
  @apply p-6 rounded-full inline-block;

  @apply bg-white;

  &.with-background {
    @apply bg-neutral-background;
    .c-user-stack__user {
      @apply outline-neutral-background;
    }
  }

  .overflow-text {
    @apply mx-4 text-m text-neutral-text-weak;
  }

  .c-user-stack__user {
    @apply outline outline-4 outline-white;
    border-radius: 48px;
    &.translated {
      @apply ml-[-12px];
    }
  }

  &.small {
    @apply p-4;
    .c-user-stack__user.translated {
      @apply ml-[-8px] outline-2;
    }
    .overflow-text {
      @apply mx-4 text-base;
    }
  }

  &.large {
    @apply p-8;
    .c-user-stack__user.translated {
      @apply ml-[-24px];
      outline-width: 6px;
    }
    .overflow-text {
      @apply mx-8 text-lg;
    }
  }
}
</style>
